import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ExperimentContextType {
  program: string | undefined;
  setProgram: React.Dispatch<React.SetStateAction<string | undefined>>;
  experimentId: string | undefined;
  setExperiment: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ExperimentContext = createContext<ExperimentContextType | undefined>(undefined);

export function ExperimentProvider({ children }: { children: ReactNode }) {
  const [programId, setProgram] = useState<string | undefined>(undefined);
  const [experimentId, setExperiment] = useState<string | undefined>(undefined);

  const value = React.useMemo(
    () => ({ experimentId, setExperiment, program: programId, setProgram }),
    [experimentId, programId]
  );

  return <ExperimentContext.Provider value={value}>{children}</ExperimentContext.Provider>;
}

export const useExperimentContext = () => {
  const context = useContext(ExperimentContext);
  if (!context) {
    throw new Error('useExperimentContext must be used within an ExperimentProvider');
  }
  return context;
};
