import { useState, useReducer, useEffect } from 'react';
import useSWR from 'swr';

import { useOktaAuth } from '@okta/okta-react';
import { Box } from '@nike/eds';
import { SampleConfiguration } from '@nike.innovation/talos-core';

import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import {
  CommandPageState,
  commandPageReducer,
  getInitialCommandPageState,
} from '../../command/command-page-reducer';
import { PCDModal } from '../point-cloud/pcd-modal';
import { environment } from '../../../environments/environment';

import { SipingHeader } from './siping-header';
import { SipingSnackBar } from './siping-snack-bar';
import { SipingDevices } from './siping-devices';
import { SipingSipeSection } from './siping-sipe-section';
import { distinctByKind } from '../../core/utils';
import { MorphSection } from '../morphing/morph-section';
import { ScanSection } from '../scan/scan-section';

export function SipingPage() {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  if (!token) {
    throw new Error('Error retrieving access token');
  }

  const { data: sipingDevices } = useSWR(
    `applications/siping/devices`,
    talosPyWebViewClient.sipeDevices,
    { refreshInterval: 2000 }
  );

  const { data: scanDevices } = useSWR(
    `applications/scan/devices`,
    talosPyWebViewClient.scanDevices,
    { refreshInterval: 2000 }
  );

  const initialState: CommandPageState = getInitialCommandPageState();
  const [commandPageState, dispatch] = useReducer(commandPageReducer, initialState);

  const [isGoHomeInUse, setIsGoHomeInUse] = useState(false);

  const [pcdModalVis, setPcdModalVis] = useState(false);
  const [pcd, setPcd] = useState('');

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;

  const updateShowPcd = (pcdString: string) => {
    setPcd(pcdString);
    setPcdModalVis(true);
  };
  const allDevices = distinctByKind(scanDevices || [], sipingDevices || []);

  const emptySampleConfig: SampleConfiguration = {
    program: null,
    experimentId: null,
    sampleId: null,
  };

  useEffect(() => {
    if (token) {
      talosPyWebViewClient.setUserToken(token);
    }
  }, [token]);

  return (
    <Box>
      <PCDModal pcd={pcd} pcdModalVis={pcdModalVis} setPcdModalVis={setPcdModalVis} />
      <SipingSnackBar commandPageState={commandPageState} dispatch={dispatch} />

      <SipingHeader commandPageState={commandPageState} setIsGoHomeInUse={setIsGoHomeInUse} />
      <SipingDevices devices={allDevices} />
      <ScanSection
        commandPageState={commandPageState}
        dispatch={dispatch}
        token={token}
        isPywebviewAvailable={isPywebviewAvailable}
        scanDevices={scanDevices}
        isGoHomeInUse={isGoHomeInUse}
        updateShowPcd={updateShowPcd}
        sampleConfig={emptySampleConfig}
      />

      <MorphSection
        commandPageState={commandPageState}
        dispatch={dispatch}
        token={token}
        isPywebviewAvailable={isPywebviewAvailable}
      />

      <SipingSipeSection
        commandPageState={commandPageState}
        dispatch={dispatch}
        token={token}
        isPywebviewAvailable={isPywebviewAvailable}
        sipingDevices={sipingDevices}
        isGoHomeInUse={isGoHomeInUse}
        sampleConfig={emptySampleConfig}
      />
    </Box>
  );
}
