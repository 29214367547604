import axios from 'axios';

import { Experiment, MetricFile } from '@nike.innovation/talos-core';

import { environment } from '../../environments/environment';

export function getFileNameFromMetricKey(key: string): string {
  const lastSlashIndex = key.lastIndexOf('/');
  return key.slice(lastSlashIndex + 1);
}

export const downloadBinaryFile = (data: Blob, filename: string): void => {
  const href = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(href);
};

export const getRowMetrics = async (
  authToken: string,
  cellId: string,
  appId: string,
  phaseId: string,
  timestamp: number
) => {
  if (!authToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const key = `api/v1/cells/${cellId}/applications/${appId}/phases/${phaseId}/timestamp/${timestamp}/files`;
  const response = await axios.get(`${environment.dataApiBaseUrl}/${key}`, axiosConfig);
  const fileNames: string[] = [];
  const getMetricsPromises = Object.entries(response.data).map(
    ([_, metricFileObject]: [string, any]) => {
      fileNames.push(getFileNameFromMetricKey(metricFileObject.file));
      return axios.get(metricFileObject.url, { responseType: 'blob' }).catch(() => undefined);
    }
  );
  const metricFiles = await Promise.all(getMetricsPromises);

  metricFiles.forEach((file, index) => {
    if (file) {
      downloadBinaryFile(
        new Blob([file.data], { type: file.headers['content-type'] }),
        fileNames[index]
      );
    }
  });
};

export const downloadMetrics = async (files: MetricFile[]) => {
  const fileNames = files.map(metricFile => getFileNameFromMetricKey(metricFile.file));

  const fileRequests = files.map(metricFile =>
    axios.get(metricFile.url, { responseType: 'blob' }).catch(() => undefined)
  );
  const filesResponse = await Promise.all(fileRequests);

  filesResponse.forEach((file, index) => {
    if (file) {
      downloadBinaryFile(
        new Blob([file.data], { type: file.headers['content-type'] }),
        fileNames[index]
      );
    }
  });
};

export const attachFilesToSample = async (
  program: string,
  experimentId: string,
  sampleId: string,
  files: FileList,
  authToken: string
): Promise<void> => {
  if (!authToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const fileNames = Array.from(files).map(file => file.name);
  try {
    const response = await axios.post(
      `${environment.dataApiBaseUrl}/api/v1/experiments/${program}/${experimentId}/${sampleId}/files`,
      { files: fileNames },
      axiosConfig
    );
    const presignedUrls = response.data;

    Array.from(files).forEach(async file => {
      const { url } = presignedUrls.find(
        (urlObj: { fileName: string }) => urlObj.fileName === file.name
      );
      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
    });
  } catch (error) {
    console.error('Error uploading files:', error);
  }
};

export const downloadResultFile = async (experiment: Experiment, authToken: string) => {
  if (!authToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const key = `api/v1/experiments/${experiment.program}/${experiment.id}/test-results`;
  const response = await axios.get(`${environment.dataApiBaseUrl}/${key}`, axiosConfig);
  const presignedUrl = response.data;

  const fileResponse = await axios
    .get(presignedUrl, { responseType: 'blob' })

    .catch(() => undefined);
  if (fileResponse) {
    downloadBinaryFile(
      new Blob([fileResponse.data], { type: fileResponse.headers['content-type'] }),
      experiment.testResults
    );
  }
};
