import { Route, Routes } from 'react-router';
import { ExperimentIndexPage } from './index-page/experiment-index-page';
import { ExperimentDetailsPage } from './details-page/experiment-details-page';
import { ExperimentProvider } from './contexts/experiment-context';

export function ExperimentRoutes() {
  return (
    <Routes>
      <Route
        path=":experimentId"
        element={
          <ExperimentProvider>
            <ExperimentDetailsPage />
          </ExperimentProvider>
        }
      />
      <Route path="" element={<ExperimentIndexPage />} />
    </Routes>
  );
}
