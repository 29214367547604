import { Dispatch, SetStateAction, useState } from 'react';

import { Card, Text, Table, TableHeading, Box, Button, Spinner } from '@nike/eds';
import { ResponseSchema, Sample } from '@nike.innovation/talos-core';

import { ResponseTableRow } from './response-table.row';
import { downloadMetrics } from '../../api-client/get-row-metrics';
import { environment } from '../../../environments/environment';

export function ResponseTable({
  samples,
  responseSchema,
  setSipingModalOpen,
  setResultsUploadModalOpen,
  setSelectedSample,
}: {
  samples: Sample[];
  responseSchema: ResponseSchema;
  setSipingModalOpen: Dispatch<SetStateAction<boolean>>;
  setResultsUploadModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedSample: Dispatch<SetStateAction<string>>;
}) {
  const headers = [
    'Sample #',
    'Treatment #',
    ...responseSchema,
    'State',
    'Metrics',
    'Uploaded Files',
    '',
    '',
  ];

  const openSipingModal = (sample: Sample) => {
    setSelectedSample(sample.sampleId);
    setSipingModalOpen(true);
  };

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;

  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <Card className="eds-elevation--2 eds-spacing--mb-24">
      <Box className="eds-flex eds-flex--justify-content-space-between">
        <Text font="title-4" className="eds-spacing--mb-16">
          Response Table
        </Text>
        <Box className="eds-flex eds-gap--8">
          <Button
            onClick={() => {
              setResultsUploadModalOpen(true);
            }}
          >
            Update from CSV
          </Button>
          {!isPywebviewAvailable ? (
            <Button
              onClick={async () => {
                setIsDownloading(true);
                await downloadMetrics(samples.flatMap(sample => sample.metricFiles));
                setIsDownloading(false);
              }}
            >
              {isDownloading ? <Spinner /> : 'Download all metrics'}
            </Button>
          ) : null}
        </Box>
      </Box>
      <Table width="100%">
        <thead>
          <tr>
            {headers.map(column => (
              <TableHeading>{column}</TableHeading>
            ))}
          </tr>
        </thead>

        <tbody>
          {samples.map(sample => (
            <ResponseTableRow
              sample={sample}
              responseSchema={responseSchema}
              openSipingModal={openSipingModal}
            />
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
