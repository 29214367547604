import { useEffect } from 'react';
import useSWR, { mutate } from 'swr';

import { Experiment } from '@nike.innovation/talos-core';

import { environment } from '../../environments/environment';

export function useExperiment(
  program: string,
  experimentId: string,
  token: string,
  stateVersion: number
): {
  experiment: Experiment | undefined;
  isLoading: boolean;
  isError: any;
} {
  const key = `api/v1/experiments/${program}/${experimentId}`;

  const fetcher = async () => {
    const response = await fetch(`${environment.dataApiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch experiment');
    }

    const experiment = await response.json();
    return experiment;
  };

  const { data, error } = useSWR(key, fetcher);

  // force refresh
  useEffect(() => {
    mutate(key);
  }, [stateVersion]);

  return {
    experiment: data,
    isLoading: !error && !data,
    isError: error,
  };
}
