import { useRef, useState } from 'react';

import { ResponseSchema, Sample } from '@nike.innovation/talos-core';
import { TableCell, Button, Spinner, ButtonGroup, Icon } from '@nike/eds';

import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { downloadMetrics, getFileNameFromMetricKey } from '../../api-client/get-row-metrics';
import { environment } from '../../../environments/environment';
import { useExperimentContext } from '../contexts/experiment-context';

export function ResponseTableRow({
  sample,
  responseSchema,
  openSipingModal,
}: {
  sample: Sample;
  responseSchema: ResponseSchema;
  openSipingModal: (sample: Sample) => void;
}) {
  const fileNamesString = sample.metricFiles
    .map(metricFile => getFileNameFromMetricKey(metricFile.file))
    .join(', ');

  const { oktaAuth } = useOktaAuth();

  const token = oktaAuth.getAccessToken();
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { experimentId, program } = useExperimentContext();
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    setSelectedFiles(files);

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (files && experimentId && program) {
      setUploadingFiles(true);
      const fileNames = Array.from(files).map(file => file.name);
      try {
        const response = await axios.post(
          `${environment.dataApiBaseUrl}/api/v1/experiments/${program}/${experimentId}/samples/${sample.sampleId}/files`,
          {
            files: fileNames,
          },
          axiosConfig
        );
        const presignedUrls = response.data;
        console.log('Presigned URLs:', presignedUrls);

        const uploadPromises = Array.from(files).map(file => {
          const { url } = presignedUrls.find(
            (urlObj: { fileName: string }) => urlObj.fileName === file.name
          );
          return axios.put(url, file, {
            headers: {
              'Content-Type': file.type,
            },
          });
        });
        await Promise.all(uploadPromises);
        setUploadingFiles(false);
      } catch (error) {
        console.error('Error uploading files:', error);
        setUploadingFiles(false);
      }
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAttachedFileButtonClick = async (fileName: string) => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (experimentId && program) {
      try {
        const response = await axios.get(
          `${environment.dataApiBaseUrl}/api/v1/experiments/${program}/${experimentId}/samples/${sample.sampleId}/files/${fileName}`,
          axiosConfig
        );
        const presignedUrl = response.data[0].url;

        const fileResponse = await axios.get(presignedUrl, {
          responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    }
  };

  return (
    <tr>
      <TableCell>{sample.sampleId}</TableCell>
      <TableCell>{sample.treatmentId}</TableCell>
      {responseSchema.map(response => (
        <TableCell>{sample.response[response]}</TableCell>
      ))}
      <TableCell>{sample.state}</TableCell>
      <TableCell>{fileNamesString}</TableCell>
      <TableCell>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {sample.files.map(file => (
            <Button
              key={file}
              variant="ghost"
              size="small"
              onClick={() => handleAttachedFileButtonClick(file)}
            >
              {file}
            </Button>
          ))}
        </div>
      </TableCell>
      <TableCell>
        {isPywebviewAvailable ? (
          <ButtonGroup>
            <Button
              variant="secondary"
              disabled={!isPywebviewAvailable}
              onClick={() => {
                openSipingModal(sample);
              }}
              size="small"
            >
              Run
            </Button>
            <Button
              variant="secondary"
              size="small"
              afterSlot={<Icon name="Upload" />}
              onClick={handleUploadClick}
              disabled={uploadingFiles}
            >
              {uploadingFiles ? <Spinner /> : 'Upload'}
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileSelect}
              multiple
            />
          </ButtonGroup>
        ) : null}
      </TableCell>
      <TableCell>
        {!isPywebviewAvailable ? (
          <Button
            size="small"
            variant="secondary"
            disabled={sample.metricFiles.length === 0 || isDownloading}
            onClick={async () => {
              setIsDownloading(true);
              await downloadMetrics(sample.metricFiles);
              setIsDownloading(false);
            }}
          >
            {isDownloading ? <Spinner /> : 'Download metrics'}
          </Button>
        ) : null}
      </TableCell>
    </tr>
  );
}
