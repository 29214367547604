import { useEffect } from 'react';
import useSWR, { mutate } from 'swr';

import { Sample } from '@nike.innovation/talos-core';

import { environment } from '../../environments/environment';

export function useSamples(
  program: string,
  experimentId: string,
  token: string,
  stateVersion: number
): {
  samples: Sample[] | undefined;
  isLoading: boolean;
  isError: any;
} {
  const key = `api/v1/experiments/${program}/${experimentId}/samples`;

  const fetcher = async () => {
    const response = await fetch(`${environment.dataApiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch samples');
    }

    const samples = await response.json();
    return samples;
  };

  const { data, error } = useSWR(key, fetcher);

  const formattedSamples = data?.map((sample: Sample) => ({
    ...sample,
    sampleId: sample.sampleId.replace('sampleId#', ''),
    treatmentId: sample.treatmentId.replace('treatmentId#', ''),
  }));

  // force refresh
  useEffect(() => {
    mutate(key);
  }, [stateVersion]);

  return {
    samples: formattedSamples,
    isLoading: !error && !data,
    isError: error,
  };
}
