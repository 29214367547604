import { SetStateAction, Dispatch, useState, useReducer, useEffect } from 'react';
import useSWR from 'swr';

import { Modal, Box, Text } from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';
import { SampleConfiguration } from '@nike.innovation/talos-core';

import {
  CommandPageState,
  commandPageReducer,
  getInitialCommandPageState,
} from '../../command/command-page-reducer';
import { environment } from '../../../environments/environment';
import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';

import { SipingHeader } from '../../application/siping/siping-header';
import { SipingSnackBar } from '../../application/siping/siping-snack-bar';
import { SipingDevices } from '../../application/siping/siping-devices';
import { SipingSipeSection } from '../../application/siping/siping-sipe-section';
import { distinctByKind } from '../../core/utils';
import { PCDModal } from '../../application/point-cloud/pcd-modal';
import { ScanSection } from '../../application/scan/scan-section';

export function SipingModal({
  sipingModalOpen,
  setSipingModalOpen,
  refreshPage,
  sampleConfig,
}: {
  sipingModalOpen: boolean;
  setSipingModalOpen: Dispatch<SetStateAction<boolean>>;
  refreshPage: () => void;
  sampleConfig: SampleConfiguration;
}) {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  if (!token) {
    throw new Error('Error retrieving access token');
  }

  const { data: sipingDevices } = useSWR(
    `applications/siping/devices`,
    talosPyWebViewClient.sipeDevices,
    { refreshInterval: 2000 }
  );

  const { data: scanDevices } = useSWR(
    `applications/scan/devices`,
    talosPyWebViewClient.scanDevices,
    { refreshInterval: 2000 }
  );

  const initialState: CommandPageState = getInitialCommandPageState();
  const [commandPageState, dispatch] = useReducer(commandPageReducer, initialState);

  const [pcdModalVis, setPcdModalVis] = useState(false);
  const [pcd, setPcd] = useState('');

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;

  const updateShowPcd = (pcdString: string) => {
    setPcd(pcdString);
    setPcdModalVis(true);
  };
  const allDevices = distinctByKind(scanDevices || [], sipingDevices || []);

  // TODO: We should properly account for this, however, this state is per component. There is currently no way to track this across pages/components.
  const [isGoHomeInUse, setIsGoHomeInUse] = useState(false);

  useEffect(() => {
    if (token) {
      talosPyWebViewClient.setUserToken(token);
    }
  }, [token]);

  return (
    <Modal
      isOpen={sipingModalOpen}
      onDismiss={() => {
        setSipingModalOpen(false);
        dispatch({ kind: 'TOGGLE_SNACK', newShowSnack: false });
        refreshPage();
      }}
      headerSlot=""
    >
      <Box>
        <PCDModal pcd={pcd} pcdModalVis={pcdModalVis} setPcdModalVis={setPcdModalVis} />
        <SipingSnackBar commandPageState={commandPageState} dispatch={dispatch} />

        <SipingHeader commandPageState={commandPageState} setIsGoHomeInUse={setIsGoHomeInUse} />
        <SipingDevices devices={allDevices} />
        <ScanSection
          commandPageState={commandPageState}
          dispatch={dispatch}
          token={token}
          isPywebviewAvailable={isPywebviewAvailable}
          scanDevices={scanDevices}
          isGoHomeInUse={isGoHomeInUse}
          updateShowPcd={updateShowPcd}
          sampleConfig={sampleConfig}
        />
        <SipingSipeSection
          commandPageState={commandPageState}
          dispatch={dispatch}
          token={token}
          isPywebviewAvailable={isPywebviewAvailable}
          sipingDevices={sipingDevices}
          isGoHomeInUse={isGoHomeInUse}
          sampleConfig={sampleConfig}
        />
        <Text font="subtitle-1" className="eds-spacing--mt-16">
          Note: Remember to physically tag the sample after Sipe completes with format: TBD
        </Text>
      </Box>
    </Modal>
  );
}
