import { Modal, TextField, StatusIndicator, Text, TextGroup } from '@nike/eds';
import { Dispatch, SetStateAction, useState } from 'react';
import axios from 'axios';
import { match } from 'ts-pattern';

import { useOktaAuth } from '@okta/okta-react';
import { Experiment, ResponseSchema } from '@nike.innovation/talos-core';

import { environment } from '../../../environments/environment';

export function ResultsUploadModal({
  modalOpen,
  setModalOpen,
  experiment,
  refreshPage,
}: {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  experiment: Experiment;
  refreshPage: () => void;
}) {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  if (!token) {
    throw new Error('Error retrieving access token');
  }

  const [parseSuccess, setParseSuccess] = useState<boolean | null>(null);

  const onParseSubmit = async (file: File, responseSchema: ResponseSchema) => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const body = {
      file,
      responseSchema,
    };

    await axios
      .post(
        `${environment.dataApiBaseUrl}/api/v1/experiments/${experiment.program}/${experiment.id}/test-results`,
        body,
        axiosConfig
      )
      .then(() => {
        setParseSuccess(true);
        refreshPage();
      })
      .catch(err => {
        setParseSuccess(false);
        console.log(err);
      });
  };

  return (
    <Modal
      isOpen={modalOpen}
      onDismiss={() => {
        setModalOpen(false);
      }}
      headerSlot="Upload results CSV"
    >
      {match(parseSuccess)
        .with(true, () => <StatusIndicator label="Status: Success" status="success" size="large" />)
        .with(false, () => <StatusIndicator label="Status: Fail" status="danger" size="large" />)
        .otherwise(() => (
          <StatusIndicator label="Status: N/A" status="neutral" size="large" />
        ))}
      <TextField
        type="file"
        accept=".csv"
        className="eds-spacing--mb-24"
        onChange={async e => {
          if (e.target !== null && e.target.files !== null) {
            await onParseSubmit(e.target.files[0], experiment.responseSchema);
          }
        }}
        id="response"
        label=""
      />
      <TextGroup>
        <Text font="subtitle-1">Ensure that the CSV is formatted as follows:</Text>
        <ul style={{ listStyleType: 'disc', paddingLeft: '1em' }}>
          <Text as="li" font="body-3">
            Rows contain all Sample data you wish to update
          </Text>
          <Text as="li" font="body-3">
            First header must be the &quot;Sample #&quot;
          </Text>
          <Text as="li" font="body-3">
            The rest of the headers will be used to define the response schema
          </Text>
        </ul>
      </TextGroup>
    </Modal>
  );
}
