import axios from 'axios';
import { useNavigate } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import { Box, Text } from '@nike/eds';
import { Experiment } from '@nike.innovation/talos-core';

import { environment } from '../../environments/environment';
import { ExperimentForm } from './experiment-form';

const emptyExperiment: Experiment = {
  program: 'Siping' as const,
  id: '',
  name: '',
  confluenceLink: '',
  treatmentTable: [],
  testResults: '',
  responseSchema: [],
};

export function NewExperimentPage() {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const onSubmit = async (experiment: Experiment) => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${oktaToken}`,
      },
    };

    const response = await axios.post(
      `${environment.dataApiBaseUrl}/api/v1/experiments/${experiment.program}/${experiment.id}`,
      experiment,
      axiosConfig
    );

    if (response.status === 200) {
      navigate('/experiments');
    }
  };

  return (
    <Box>
      <Text font="title-1" as="h1" className="eds-spacing--mb-32">
        Create new experiment
      </Text>

      <ExperimentForm
        initialTreatmentHeaders={Array.from({ length: 10 }, () => '')}
        initialTreatmentLevels={Array.from({ length: 10 }, () =>
          Array.from({ length: 11 }, () => '')
        )}
        initialExperiment={emptyExperiment}
        onSubmit={onSubmit}
      />
    </Box>
  );
}
