import { Dispatch, useEffect, useState } from 'react';

import { Box, Button, Select, Spinner, Text } from '@nike/eds';
import { Measure } from '@nike/nike-design-system-icons';

import { MorphAlgorithm } from '@nike.innovation/talos-core';
import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import { CommandPageAction, CommandPageState } from '../../command/command-page-reducer';
import { MorphParameterField } from './morph-parameter-field';

export function MorphSection({
  commandPageState,
  dispatch,
  token,
  isPywebviewAvailable,
}: {
  commandPageState: CommandPageState;
  dispatch: Dispatch<CommandPageAction>;
  token: string;
  isPywebviewAvailable: boolean;
}) {
  const [algorithmName, setAlgorithmName] = useState<string>('CPD');
  const [morphAlgorithm, setMorphAlgorithm] = useState<MorphAlgorithm | undefined>(undefined);

  useEffect(() => {
    async function fetchMorphParameters() {
      if (token) {
        setMorphAlgorithm(await talosPyWebViewClient.morphParameters(algorithmName));
      }
    }

    fetchMorphParameters();
  }, [algorithmName, token, setMorphAlgorithm]);

  return (
    <Box className="eds-spacing--mb-32">
      {isPywebviewAvailable && (
        <Box>
          <Text font="title-2" className="eds-spacing--mb-16">
            Morph
          </Text>

          <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--32 eds-spacing--mb-16">
            <Select
              id="morph-algo"
              required
              label="Choose algorithm"
              defaultValue={{ label: 'CPD', value: 'cpd' }}
              options={[{ label: 'CPD', value: 'cpd' }]}
              onChange={e => {
                setAlgorithmName(e?.value || '');
              }}
            />
          </Box>

          {morphAlgorithm && (
            <>
              <Box className="eds-flex eds-gap--32 eds-spacing--mb-16">
                {morphAlgorithm.parameters.map(parameter => (
                  <MorphParameterField
                    key={parameter.name}
                    parameter={parameter}
                    setMorphAlgorithm={setMorphAlgorithm}
                  />
                ))}
              </Box>

              <Box>
                <Button
                  beforeSlot={<Measure />}
                  disabled={false}
                  onClick={async () => {
                    dispatch({ kind: 'START_COMMAND', commandId: 'Morph' });

                    try {
                      await talosPyWebViewClient.morph(morphAlgorithm, token);
                    } finally {
                      dispatch({ kind: 'END_COMMAND', response: '' });
                    }
                  }}
                >
                  {!commandPageState.runningCommandId ? 'Morph' : <Spinner />}
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
